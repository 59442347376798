import React, { memo } from 'react';
import mova from 'mova';
import PropTypes from 'prop-types';
import './PhoneInput.scss';
import InputGroup from '@components/layout/InputGroup/InputGroup';
import { withFormsy } from 'formsy-react';
import { default as PhoneInputLibrary } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { phone } from 'phone';

const t = mova.ns('base');

function PhoneInput(props) {
  const { setValue, errorMessage, isPristine } = props;
  const errorPresent = !isPristine && Boolean(errorMessage);

  const phoneChanged = (newValue, country ) => {
    if (!newValue) return;
    const formattedPhone = phone(newValue, { country: country?.countryCode });
    if (formattedPhone.isValid) {
      setValue(formattedPhone.phoneNumber); 
    } else {
      setValue(newValue);
    }
  };
  return (
    <InputGroup
      error={errorMessage}
      errorPresent={errorPresent}
    >
      <PhoneInputLibrary
      containerClass={`react-tel-input`}
      dropdownClass={`react-tel-input`}
      searchClass={`react-tel-input`}
      preferredCountries={['es','fi','ua']}
      country={'ua'} 
      searchPlaceholder={t('submitFilterForm')}
      enableSearch={true}
      onChange={phoneChanged}
      />
    </InputGroup>
  );
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isPristine: PropTypes.bool.isRequired,
};

PhoneInput.defaultProps = {
  label: undefined,
  defaultValue: undefined,
  fullWidth: false,
  fontSize: 16,
  onChange: undefined,
  errorMessage: null,
  value: '',
};

export default memo(withFormsy(PhoneInput));
