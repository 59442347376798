import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './LoginRegisterModal.scss';
import TextInput from '@components/inputs/TextInput/TextInput';
import Checkbox from '@components/inputs/Checkbox/Checkbox';
import Button from '@components/inputs/Button/Button';
// import SocialLoginButton from '@components/inputs/SocialLoginButton/SocialLoginButton';
import PhoneInput from '@components/inputs/PhoneInput/PhoneInput';
import ConfirmLegal from '@components/display/ConfirmLegal/ConfirmLegal';
import { openModal } from '@actions/modal';
import { connect } from 'react-redux';
import Modal from '@components/feedback/Modal/Modal';
import { LOGIN_MODAL } from '@constants/modal';
import TextLink from '@components/navigation/TextLink/TextLink';
// import { PROVIDER_FACEBOOK } from '@constants/app';
import Form from '@components/inputs/Form/Form';
import { userSignUp } from '@actions/user';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import { phoneRegexp } from '@utils/validation/validationUtils';
import LocaleService from '@services/Locale.service';

const t = mova.ns('containers.LoginRegisterModal');
const errorsT = mova.ns('errors.validation');

function RegisterModal({ showLoginModal, registerUser, meta }) {
  const submitForm = (model) => registerUser(model, meta);
  const promoMessage = meta?.place?.translate?.channels?.[meta?.channelId]?.promo?.[LocaleService.getLocale()];

  return (
    <Modal title={promoMessage || t('signUpTitle')} className='login-register-modal'>
      {/*
      <div className='login-register-modal__row'>
        <SocialLoginButton provider={PROVIDER_FACEBOOK} fullWidth />
      </div>
      <div className='login-register-modal__row'>
        <SocialLoginButton provider={PROVIDER_GOOGLE} fullWidth />
      </div>
      <div className='login-register-modal__row login-register-modal__or'>
        {t('or')}
      </div>
      */}
      <Form onValidSubmit={submitForm}>
        <div className='login-register-modal__row--large'>
          <TextInput
            name='firstName'
            placeholder={t('name')}
            fullWidth
            fontSize={16}
            validations={{
              minLength: 2,
            }}
            validationErrors={{
              minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='login-register-modal__row--large'>
          <TextInput
            name='lastName'
            placeholder={t('surname')}
            fullWidth
            fontSize={16}
            validations={{
              minLength: 2,
            }}
            validationErrors={{
              minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='login-register-modal__row--large'>
          <PhoneInput
            name='phone'
            fullWidth
            validations={{
              matchRegexp: phoneRegexp
            }}
            validationErrors={{
              matchRegexp: errorsT('phone'),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='login-register-modal__row--large'>
          <TextInput
            name='email'
            placeholder={t('email')}
            fullWidth
            fontSize={16}
            validations='isEmail'
            validationErrors={{
              isEmail: errorsT('email'),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='login-register-modal__row--large'>
          <TextInput
            name='password'
            placeholder={t('password')}
            type='password'
            fullWidth
            fontSize={16}
            validations={{
              minLength: 4,
            }}
            validationErrors={{
              minLength: errorsT(`minLength.${getPluralsFactor(4)}`, { symbols: 4 }),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='login-register-modal__row--large'>
          <TextInput
            name='repeatPassword'
            placeholder={t('repeatPassword')}
            type='password'
            fullWidth
            fontSize={16}
            validations={{
              repeatPassword: (values, value) => values.password === value
            }}
            validationErrors={{
              repeatPassword: errorsT('repeatPassword'),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='login-register-modal__row'>
          <Checkbox name='rememberMe'>{t('rememberMe')}</Checkbox>
        </div>
        <div className='login-register-modal__actions login-register-modal__row--small'>
          <Button fullWidth size='medium' behavior='submit'>{t('signUp')}</Button>
        </div>
      </Form>
      <div className='login-register-modal__new-user'>
        <span>{t('haveAccountQuestion')}</span>&nbsp;
        <TextLink type='success' onClick={() => showLoginModal(meta)}>{t('signInLink')}</TextLink>
      </div>
      <ConfirmLegal buttonName={t('signUp')} />
    </Modal>
  );
}

RegisterModal.propTypes = {
  showLoginModal: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  meta: PropTypes.object
};

RegisterModal.defaultProps = {
  meta: undefined
};

const mapStateToProps = ({ modal }) => ({
  meta: modal.params
});

const mapDispatchToProps = (dispatch) => ({
  showLoginModal: (params) => dispatch(openModal(LOGIN_MODAL, params)),
  registerUser: (data, meta) => dispatch(userSignUp(data, meta))
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(RegisterModal));
