import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './LoginRegisterModal.scss';
import TextInput from '@components/inputs/TextInput/TextInput';
import Button from '@components/inputs/Button/Button';
// import SocialLoginButton from '@components/inputs/SocialLoginButton/SocialLoginButton';
import TextLink from '@components/navigation/TextLink/TextLink';
import Checkbox from '@components/inputs/Checkbox/Checkbox';
import { connect } from 'react-redux';
import { openModal } from '@actions/modal';
import Modal from '@components/feedback/Modal/Modal';
import { REGISTRATION_MODAL, FORGOT_PASSWORD_MODAL } from '@constants/modal';
// import { PROVIDER_FACEBOOK } from '@constants/app';
import Form from '@components/inputs/Form/Form';
import PhoneInput from '@components/inputs/PhoneInput/PhoneInput';
import { userSignIn } from '@actions/user';
import { phoneRegexp } from '@utils/validation/validationUtils';
import LocaleService from '@services/Locale.service';

const t = mova.ns('containers.LoginRegisterModal');
const errorsT = mova.ns('errors.validation');

function LoginModal(props) {
  const { showRegisterModal, showForgotPasswordModal, loginUser, meta } = props;

  const submitForm = (model) => loginUser(model, meta);
  const promoMessage = meta?.place?.translate?.channels?.[meta?.channelId]?.promo?.[LocaleService.getLocale()];

  return (
    <Modal title={promoMessage || t('signInTitle')} className='login-register-modal'>
      {/*
      <div className='login-register-modal__row'>
        <SocialLoginButton provider={PROVIDER_FACEBOOK} fullWidth />
      </div>
      <div className='login-register-modal__row'>
        <SocialLoginButton provider={PROVIDER_GOOGLE} fullWidth />
      </div>
      <div className='login-register-modal__row login-register-modal__or'>
        {t('or')}
      </div>
      */}
      <Form onValidSubmit={submitForm}>
        <div className='login-register-modal__row--large'>
          <PhoneInput
            name='phone'
            fullWidth
            required
            validations={{
              matchRegexp: phoneRegexp
            }}
            validationErrors={{
              matchRegexp: errorsT('phone'),
              isDefaultRequiredValue: errorsT('required')
            }}
          />
        </div>
        <div className='login-register-modal__row--large'>
          <TextInput
            placeholder={t('password')}
            type='password'
            name='password'
            fullWidth
            fontSize={16}
            required
            validationErrors={{
              isDefaultRequiredValue: errorsT('required')
            }}
          />
          <div className='login-register-modal__forgot-password'>
            <TextLink
              onClick={showForgotPasswordModal}
              type='text'
              className='login-register-modal__forgot-password-link'
            >
              {t('forgotPassword')}
            </TextLink>
          </div>
        </div>
        <div className='login-register-modal__row'>
          <Checkbox name='rememberMe'>{t('rememberMe')}</Checkbox>
        </div>
        <div className='login-register-modal__actions login-register-modal__row--small'>
          <Button fullWidth size='medium' behavior='submit'>{t('signIn')}</Button>
        </div>
      </Form>
      <div className='login-register-modal__new-user'>
        <span>{t('newUserQuestion')}</span>&nbsp;
        <TextLink type='success' onClick={() => showRegisterModal(meta)}>{t('signUpLink')}</TextLink>
      </div>
    </Modal>
  );
}

LoginModal.propTypes = {
  showRegisterModal: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  showForgotPasswordModal: PropTypes.func.isRequired,
  meta: PropTypes.object
};

LoginModal.defaultProps = {
  meta: undefined
};

const mapStateToProps = ({ modal }) => ({
  meta: modal.params
});

const mapDispatchToProps = (dispatch) => ({
  showRegisterModal: (params) => dispatch(openModal(REGISTRATION_MODAL, params)),
  showForgotPasswordModal: () => dispatch(openModal(FORGOT_PASSWORD_MODAL)),
  loginUser: (data, meta) => dispatch(userSignIn(data, meta))
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(LoginModal));
