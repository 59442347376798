import React, { useEffect } from 'react';
import mova from 'mova';
import PropTypes from 'prop-types';
import './Payment.scss';
import Loading from '@components/feedback/Loading/Loading';
import Entity404 from '../Error/Entity404';
import PaymentLinkExpired from './PaymentLinkExpired';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBillingByHash } from '@actions/billing';
import PaymentForm from './components/PaymentForm';
import { DEFAULT_LANG } from '@constants/app';
import { get } from '@utils/lo/lo';
import PaymentSuccess from './PaymentSuccess';
import PlaceReservationPaymentInfo from "./components/PlaceReservationPaymentInfo";
import CertificateCard from "../../components/display/CertificateCard/CertificateCard";

const t = mova.ns('pages.Payment');

function Payment({ match, getBillingByHash, loading, billing, user }) {
  const { billingHash } = match.params;

  useEffect(() => {
    getBillingByHash(billingHash);
  }, [billingHash, getBillingByHash]);

  if (loading) {
    return <Loading />;
  } else if (!billing) {
    return <Entity404 entity={t('reservation')} />;
  } else if (!['READY', 'INPROCESS'].includes(billing.status)) {
    return <PaymentLinkExpired place={billing?.reservation?.place} />;
  }

  const { amount, reservation, certificate, place } = billing;

  return (
    <div className='payment'>
      <div className='payment__reservation-info reservation-info'>
        {reservation && place && <PlaceReservationPaymentInfo place={place} reservation={reservation} />}
        {certificate && <CertificateCard certificate={certificate} />}

        {reservation?.busyHours?.message && (
          <div className='reservation-info__busy-hours reservation-info__row'>
            {reservation?.busyHours?.message[user?.lang || DEFAULT_LANG]}
          </div>
        )}

        {['READY', 'INPROCESS'].includes(billing.status) &&
          <PaymentForm amount={amount} billing={billing} showAmount={!certificate} />
        }
        {billing.status === 'DONE' && <PaymentSuccess billing={billing} />}
      </div>
    </div>
  );
}

Payment.propTypes = {
  match: PropTypes.object.isRequired,
  billing: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  getBillingByHash: PropTypes.func.isRequired,
  user: PropTypes.object,
};

Payment.defaultProps = {
  billing: undefined,
  user: undefined,
};

const mapStateToProps = ({ user, billing }) => ({
  user: get(user, 'details.data.user'),
  billing: billing.data,
  loading: billing.isLoading,
});

const mapDispatchToProps = {
  getBillingByHash,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Payment));
